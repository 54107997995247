.header {
	&__branding {
		a {
			img {
				width: 300px;
			}
		}
	}
	@media #{$mq-xLargeScreen} {
		&__branding {
			a {
				img {
					width: 400px;
				}
			}
		}
	}
}
.utility-nav {
	@media #{$mq-xLargeScreen} {
		&-ribbon {
			&:before {
				border-color: lighten($color-teal, 5%);
				border-left-color: transparent;
			}
			&:after {
				border-top-color: darken($color-teal, 5%);
			}
			&-link {
				background-color: $color-teal;
			}
		}
	}
}
