.job-search {
	@media #{$mq-largeScreen} {
		background: $color-red;
	}
	.button { // Applies to mobile button and desktop submit
		@extend .button-2;
	}
	&-form {
		.form-fields {
			.form-label {
				background: $color-red;
			}
		}
	}
}
